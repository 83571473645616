<template>
   <div class="row row-equal">
      <div class="flex xs12 xl6">
        <va-card :title="'New Users & Exams' + this.lastUsersRefresh">
          <va-button
            icon="fa fa-spinner"
            outline
            small
            slot="actions"
            class="mr-0"
            @click="refreshUsersData()"
          >
           Refresh Data
          </va-button>
          <div v-if="loadingUsers" class="text-center">
             <i class="fa fa-spinner fa-spin fa-3x fa-fw" />
             <p>{{ progress }}% Loaded</p>
          </div>
          <va-chart
            v-else
            class="chart"
            ref="lineChart"
            :data="userCountData"
            type="line"
          />
        </va-card>
      </div>


    <div class="flex xs12 md6 xl3">
      <va-card :title="'Pass/Fail Rate' + this.lastRateRefresh">
        <va-button
            icon="fa fa-spinner"
            outline
            small
            slot="actions"
            class="mr-0"
            @click="refreshRateData()"
          >
           Refresh Data
        </va-button>
        <va-chart
          class="chart chart--donut"
          :data="donutData"
          type="donut"
          v-if="!loading"
        />
        <div v-if="loading" class="text-center">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw" />
            <p>{{ donutProgress }}% Loaded</p>
       </div>
      </va-card>
    </div>

    <div class="flex xs12 md6 xl3">
      <dashboard-contributors-chart />
    </div>
  </div>
</template>

<script>
import DashboardContributorsChart from './DashboardContributorsList';
import firebase from 'firebase';
import moment from 'moment';
import axios from '@/scripts/interceptor.js'
export default {
  name: 'DashboardCharts',
  components: {DashboardContributorsChart},
  data() {
    return {
      lineChartFirstMonthIndex: 0,
      userCountData: {},
      donutData: {},
      loading: true,
      loadingUsers: true,
      progress: 0,
      donutProgress: 0,
      lastRateRefresh: '',
      lastUsersRefresh: '',
      userCountData: {
        labels: [],
        datasets: [
          {
            label: 'Users',
            backgroundColor: 'rgba(108,127,238,0.6)',
            borderColor: 'transparent',
            data: [],
          },
          {
            label: 'Exams',
            backgroundColor: 'rgba(113,186,255,0.6)',
            borderColor: 'transparent',
            data: [],
          },
        ],
      },
      donutData: {
        labels: ['Pass', 'Fail'],
        datasets: [{
          label: 'Population (millions)',
          backgroundColor: ['rgba(150,255,150,0.6)', 'rgba(255,150,150,0.6)'],
          data: [],
        }],
      },

    };
  },
  methods: {
    deleteSection() {
      this.lineChartFirstMonthIndex += 1;
      this.$refs.lineChart.$refs.chart.refresh();
    },
    getDate(date) {
      if (date instanceof firebase.firestore.Timestamp) {
        return date.toDate();
      } else if (typeof date === 'string') {
        return moment(date).toDate();
      }
      else if (typeof date === 'object' && date.hasOwnProperty('_seconds') && date.hasOwnProperty('_nanoseconds')) {
        return moment(date._seconds * 1000).toDate();
      }
      return null;
    },
    async fetchExamData(userDoc) {
      const examSnapshot = await userDoc.ref.collection('Exams').get();
      return examSnapshot.docs.map(doc => doc.data());
    },
    incrementCount(data, dataset) {
      const date = this.getDate(data.StartDate);
      if (date) {
        const month = moment(date).format('MMMM');
        if (dataset[month] !== undefined) {
          dataset[month]++;
        }
      }
    },
     /**
     * @title getNewUsers V2
     * @updatedAt - 2023-05-23
     * @description Fetches data for the line chart
     * @returns {Promise<void>}
    */
    async getNewUsers() {
      try {
        this.progress += 10;
        const monthsScope = Array.from({ length: 7 }, (_, i) => moment().subtract(i, 'months').format('MMMM')).reverse();
        const userChartsFunction = await axios.get(window.firebaseURL + `api/admin/chart/users/fetch`);
        const userData = userChartsFunction?.data?.data?.report
        this.progress += 40;
        const updateUserProgress = setInterval(() => {
          const progress = Math.min(this.progress + 1, 100);
          this.progress = progress;
        }, 1000);
        const testChartsFunction = await axios.get(window.firebaseURL + `api/admin/chart/exams/fetch`);
        const testData = testChartsFunction?.data?.data?.report
        clearInterval(updateUserProgress);
        this.progress += 30;
        this.userCountData = {
          labels: monthsScope,
          datasets: [
            { ...this.userCountData.datasets[0], data: Object.values(userData) },
            { ...this.userCountData.datasets[1], data: Object.values(testData) },
          ],
        };
        this.progress = 100;
        // Validate data before save
        if (
          typeof this.userCountData === 'object' &&
          this.userCountData.hasOwnProperty('labels') &&
          this.userCountData.hasOwnProperty('datasets') &&
          Array.isArray(this.userCountData.datasets) &&
          this.userCountData.datasets.length === 2
        ) {
          // Create a Firestore timestamp
          const now = firebase.firestore.Timestamp.now();
          this.lastUsersRefresh = ` - [${moment(now.toDate()).format('MM/DD/YYYY, hh:mm')}]`;
          this.userCountData.lastRefresh = now;

          // Save data to Firestore, update the field "userCountData" in the admin document from the charts collection
          await firebase.firestore()
            .collection('charts')
            .doc('admin')
            .update({
              userCountData: this.userCountData
            });
        } else {
          throw new Error('Invalid data');
        }

        return this.userCountData;
      } catch (error) {
        this.$swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    },
    async getExamsData() {
      this.donutProgress += 30;
      const passFailData = { pass: 0, fail: 0 };
      const examsQuery = await axios.put(window.firebaseURL + `api/admin/chart/pie/exams/fetch`);
      this.donutProgress += 20;
      //check if passFailData is an object and if it hjas pas and fail as integers
      if (examsQuery?.data?.data.countOfExams) {
        const passFailData = examsQuery?.data?.data?.passFailData || { pass: 0, fail: 0 };
        const now = firebase.firestore.Timestamp.now();
        this.lastRateRefresh = ` - [${moment(now.toDate()).format('MM/DD/YYYY, hh:mm')}]`
        passFailData.lastRefresh = now
        this.donutProgress += 30;
        //Save data to firestore, update the field "passFailData" in admin document from charts collection
        await firebase.firestore()
        .collection('charts')
        .doc('admin')
        .update({
          donutData: passFailData,
          completedExamsCount: examsQuery?.data?.data?.countOfExams || 0
        }).then(() => {
          console.log(passFailData)
          this.donutData.datasets[0].data = [passFailData.pass, passFailData.fail];
          this.donutProgress += 20;
          return passFailData;
        }).catch((error) => {
          console.error(error)
        })
      } else {
        throw new Error("Invalid data");
      }
    },
    async refreshUsersData() {
      this.progress = 0;
      this.loadingUsers = true;
      try {
        await this.getNewUsers();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingUsers = this.progress !== 100;
      }
    },
    async refreshRateData(){
      this.donutProgress = 0;
      this.loading = true;
      await this.getExamsData();
      this.loading = this.donutProgress !== 100; 
    },
  },
  computed: {
    donutChartDataURL() {
      return document.querySelector('.chart--donut canvas').toDataURL('image/png');
    },
  },
  async mounted() {
    try {
      this.donutProgress = 30;
      this.progress = 30;
      console.log('starting')
      let chartsData = await firebase.firestore().collection('charts').doc('admin').get()
      chartsData = chartsData.data()
      //USER & EXAMS CHART
      this.userCountData = chartsData.userCountData
      if (this.userCountData.hasOwnProperty('lastRefresh')) {
        if (this.userCountData.lastRefresh instanceof firebase.firestore.Timestamp) {
          this.lastUsersRefresh = ` - [${moment(this.userCountData?.lastRefresh.toDate()).format('MM/DD/YYYY, hh:mm')}]`
        } else {
          this.lastUsersRefresh = ' - N/A'
        }
      } else {
        this.lastUsersRefresh = ' - N/A'
      }
      //USER & EXAMS CHART

      //PASS/FAIL CHART
      this.donutData.datasets[0].data = [chartsData?.donutData?.pass, chartsData?.donutData?.fail];
      console.log(this.donutData.datasets[0].data)

      if (chartsData.donutData?.hasOwnProperty('lastRefresh')) {
        if (chartsData.donutData?.lastRefresh instanceof firebase.firestore.Timestamp) {
          this.lastRateRefresh = ` - [${moment(chartsData.donutData?.lastRefresh.toDate()).format('MM/DD/YYYY, hh:mm')}]`
        } else {
          this.lastRateRefresh = ' - N/A'
        }
      } else {
        this.lastRateRefresh = ' - N/A'
      }
      this.donutProgress += 70;
      this.progress += 70;
      //PASS/FAIL CHART


      this.loadingUsers = this.progress !== 100;
      this.loading = this.donutProgress !== 100;
    } catch (error) {
      this.$swal.fire({
        title: 'Error',
        text: 'Something went wrong while fetching data',
        icon: 'error',
      })
      this.loading = false;
      this.loadingUsers = false;
    }
  },
};
</script>

<style scoped>
  .chart {
    height: 400px;
  }
</style>
