<template>
  <va-card title="Logged In Users in past 30 days">
    <div class="row align--center mb-1">
      <div class="flex xs12 sm6">
        <va-input
          class="ma-0"
          :value="term"
          :placeholder="$t('tables.searchByName')"
          @input="search"
          removable
        >
          <va-icon
            name="fa fa-search"
            slot="prepend"
          />
        </va-input>
      </div>
    </div>

    <va-data-table
      :fields="fields"
      :data="filteredData"
      :loading="loading"
      hoverable
    >
      <template #lastLogin="props">
        {{ props.rowData.lastLogin }}
      </template>
    </va-data-table>
  </va-card>
</template>

<script>
import firebase from 'firebase';
import debounce from 'lodash/debounce';
import moment from 'moment';

export default {
  data() {
    return {
      users: [],
      loading: false,
      term: null,
      mode: 0,
    };
  },
  computed: {
    fields() {
      return [{
        name: 'name',
        title: this.$t('tables.headings.name'),
        width: '15%',
      }, {
        name: 'id',
        title: 'UserID',
        width: '15%',
      }, {
        name: 'email',
        title: this.$t('tables.headings.email'),
        width: '40%',
      }, {
        name: '__slot:lastLogin',
        title: 'Last Login',
        width: '30%',
        sortField: 'lastLogin',
      }];
    },
    filteredData() {
      return this.users;
    },
  },
  methods: {
    async getTableData() {
      const searchBackTimeMoment = moment().subtract(30, 'days').local();
      const searchBackTimestamp = firebase.firestore.Timestamp.fromDate(new Date(searchBackTimeMoment));
      const users = await firebase.firestore().collection('users').where('lastLogin', '>', searchBackTimestamp).orderBy('lastLogin', 'desc').get();
      users.docs.forEach(async (userDoc)=>{
        const user = userDoc.data();
        if (!user.developer) {
          this.users.push( {
            'id': userDoc.id,
            'name': user.displayName,
            'email': user.email,
            'lastLogin': user.lastLogin.toDate(),
          });
        }
      });
    },
    getStatusColor(status) {
      if (status === 'paid') {
        return 'success';
      }

      if (status === 'processing') {
        return 'info';
      }

      return 'danger';
    },
    resolveUser(user) {
      this.loading = true;

      setTimeout(() => {
        const idx = this.users.findIndex((u) => u.id === user.id);
        this.users.splice(idx, 1);
        this.loading = false;

        this.showToast(this.$t('dashboard.table.resolved'), {
          icon: 'fa-check',
          position: 'bottom-right',
          duration: 1500,
        });
      }, 500);
    },
    search: debounce(function(term) {
      /* eslint-disable */
      this.term = term;
    }, 400),
  },
  mounted() {
    this.getTableData();
  },
};
</script>

<style lang="scss">
</style>
